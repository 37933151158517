import cls from "./ServiceCard.module.css";
import MarginBottomContainer from "../MarginBottomContainer/MarginBottomContainer";
const ServiceCard = ({img, header, text}) => {
    return (
        <div className={cls.Card}>
            <div className={cls.imgContainer}>
                <img className={cls.img} src={img} alt="card" />
            </div>
            <div className={cls.textContainer}>
                <MarginBottomContainer margin={20}>
                    {String(header).includes("/") ? (
                            String(header)
                                .split("/")
                                .reduce((accum, current) => (
                                    <h3 className={cls.header}>{accum}<span>/</span>{current}</h3>))
                        ):
                        (<h3 className={cls.header}>{header}</h3>
                        )
                    }
                </MarginBottomContainer>
                <p className={cls.text}>{text}</p>
            </div>
        </div>
    )
}


export default ServiceCard;
