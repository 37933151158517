import Gamer from "../../shared/assets/images/Gamer.png";
import Arrow from "../../shared/assets/images/Arrow.png";
import cls from "./Main.module.css";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import ScrollButton from "../../shared/ui/ScrollButton/ScrollButton";
import LineHeader from "../../shared/ui/LineHeader/LineHeader";
const Main = () => {
    console.log(cls);
    return (
        <div id="main" className={cls.mainSection}>
            <div className="container container--main">
                <div className={cls.mainWrapper}>
                    <div className={cls.mainWrapper__info}>
                        <MarginBottomContainer margin={22}>
                            <LineHeader />
                            <Title title="Adverta Limited" />
                        </MarginBottomContainer>

                        <MarginBottomContainer margin={123}>
                            <ParagraphSection>
                                Crafting Apps You'll Love to Use. Our passion lies in creating mobile applications that captivate and engage users from the very first tap. At Adverta Limited we embarked on this journey to breathe life into innovative mobile experiences, prioritizing speed, seamless functionality, and uncompromising quality. Whether it's productivity tools, entertainment apps, or utilities, we cater to diverse needs, ensuring there's an app for every individual. Join us in shaping the future of mobile technology, one exceptional app at a time
                            </ParagraphSection>
                        </MarginBottomContainer>
                        <ScrollButton src={Arrow} href="aboutUs" label="ABOUT US" />

                    </div>
                    <div>
                        <img src={Gamer} alt=""/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Main;
