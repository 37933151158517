import Title from "../../shared/ui/Title/Title";
import card1 from "../../shared/assets/images/card1.png";
import card2 from "../../shared/assets/images/card2.png";
import card3 from "../../shared/assets/images/card3.png";
import card4 from "../../shared/assets/images/card4.png";
import cls from "./Services.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import ServiceCard from "../../shared/ui/ServiceCard/ServiceCard";
import LineHeader from "../../shared/ui/LineHeader/LineHeader";

const OurServices = () => {
    const cards = [
        {
            img: card1,
            header: "Art and design for apps",
            text: "We carry out any work related to design and art according to your individual order: texturing, animating, concept art and 2d / 3d art."
        },
        {
            img: card2,
            header: "UI / UX design",
            text: "We will create a design for any of your applications using the knowledge of our advanced specialists and high-tech solutions"
        },
        {
            img: card3,
            header: "Prototyping",
            text: "Don't know if your idea will work? We will make a prototype for you and you can test your main hypotheses on it before starting application development"
        },
        {
            img: card4,
            header: "Full cycle development",
            text: "We will develop an application from scratch, or we will bring your project to completion with the help of our team of highly specialized specialists"
        },
    ]
    return (
        <div id="services" className={cls.servicesSection} >
            <div className="container container--main">
                <div className={cls.ServicesWrapper}>
                    <div className="mission-wrapper__header">
                        <MarginBottomContainer margin={74}>
                            <LineHeader />
                            <Title title="Our development services" />
                        </MarginBottomContainer>
                    </div>
                    <div className={cls.cardsContainer}>
                        {cards.map((card) => {
                            return <ServiceCard img={card.img} text={card.text} header={card.header} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
