import Header from "./../pages/Header/Header";
import Main from "./../pages/Main/Main";
import AboutUs from "./../pages/AboutUs/AboutUs";
import Career from "./../pages/Career/Career";
import ContactUs from "./../pages/ContactUs/ContactUs";
import Footer from "./../pages/Footer/Footer";
import OurMission from "./../pages/OurMission/OurMission";
import OurServices from "../pages/OurServices/OurServices";
import Why from "../pages/Why/Why";

function App() {
    return (
        <div className="App">
            <Header />
            <Main />
            <AboutUs />
            <OurMission />
            <OurServices />
            <Why />
            <Career />
            <ContactUs />
            <Footer />
        </div>
  );
}

export default App;
