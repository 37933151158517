const GridSvg = ({width, height, opacity, d, color}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
        >
            <path
                opacity={opacity || null}
                d={d}
                fill={color}
            />
        </svg>
    )
}

export default GridSvg;
