import cls from "./Header.module.css";
import ALlogo from "../../shared/assets/images/logoAL.png";
const Header = () => {
    return (
        <header className={cls.header}>
            <div className={`container container--main ${cls.headerContainer}`}>
                <div className={cls.header__logo} onClick={() => window.location.href = "#main"}>
                    <div className={cls.logoContainer}>
                        <img src={ALlogo} alt=""/>
                        <h1 className={cls.logoText}>Adverta Limited</h1>
                    </div>
                </div>

                <nav className={cls.header__menu}>
                    <li><a href="#aboutUs">Company</a></li>
                    <li><a href="#mission">Our mission</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#career">Careers</a></li>
                    <li><a href="#contact">Contacts</a></li>
                </nav>

            </div>
        </header>
    );
};

export default Header;
