import TextField from "../../components/form/textField/textField";
import TextArea from "../form/textArea/textArea";
import CheckBoxField from "../../components/form/checkBoxField/checkBoxField";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { validator } from "../../utils/validator";
import Lottie from 'react-lottie';
import readyLottie from "../../lotties/ready.json";
import emailjs from "@emailjs/browser";
import cls from "./JobApplyForm.module.css";

const JobApplyForm = () => {
    const defaultData = useMemo(() => {
        return {
            name: "",
            lastName: "",
            country: "",
            email: "",
            position: "",
            resume: "",
            message: "",
            licence: false
        }
    }, [])
    const [data, setData] = useState(defaultData);

    const [ready, setReady] = useState(false);

    const showReady = () => {
        setReady(true);
        setTimeout(() => {
            setReady(false);
        }, 2000)
    }

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        emailjs.init("4hEiAL0nk43P5QC3p")
    }, [])

    const [errors, setErrors] = useState({});

    const handleChange = (target) => {
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const validatorConfig = {
        email: {
            isRequired: {
                message: "Email is required to fill"
            },
            isEmail: {
                message: "Check your email"
            }
        },
        lastName: {
            isRequired: {
                message: "Last name is required to fill"
            },
            min: {
                message: "Last name must contain at least 3 symbols",
                value: 3
            }
        },
        country: {
            isRequired: {
                message: "Country is required to fill"
            }
        },
        licence: {
            isRequired: {
                message: "Need to accept licence"
            }
        },
        name: {
            isRequired: {
                message: "Name is required to fill"
            },
            min: {
                message: "Name must contain at least 3 symbols",
                value: 3
            },
        }
    };

    useLayoutEffect(() => {
        if (data === defaultData) {

        } else {
            validate();
        }
    }, [data]);

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    let isValid = Object.keys(errors).length === 0 && data !== defaultData;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validate();
        if (!isValid) return;
        const serviceId = "service_95utxzd";
        const templateId = "template_9c7nmx2";
        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                ...data
            });
            showReady();
        } catch (error) {
            // console.log(error);
        } finally {
            setLoading(false);
        }
        setData(defaultData);
        setErrors({});
    };

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: readyLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form">
            {ready ? (
                <div className={cls.SuccessFixed}>
                    <Lottie
                        options={defaultOptions}
                        height={250}
                        width={250}
                    />
                </div>
            ) : null}
            <div className={cls.textFieldContainer}>
                <TextField
                    label="Name*"
                    value={data.name}
                    onChange={handleChange}
                    name="name"
                    error={errors.name}
                />
                <TextField
                    label="Last Name*"
                    value={data.lastName}
                    onChange={handleChange}
                    name="lastName"
                    error={errors.lastName}
                />
                <TextField
                    label="Country*"
                    value={data.country}
                    onChange={handleChange}
                    name="country"
                    error={errors.country}
                />
                <TextField
                    label="Email*"
                    value={data.email}
                    onChange={handleChange}
                    name="email"
                    error={errors.email}
                />
                <TextField
                    label="Position"
                    value={data.position}
                    name="position"
                    onChange={handleChange}

                />
                <TextField
                    label="Link for resume"
                    value={data.resume}
                    name="resume"
                    onChange={handleChange}
                />
                <TextArea
                    label="Message"
                    value={data.message}
                    name="message"
                    onChange={handleChange}
                />
                <CheckBoxField
                    onChange={handleChange}
                    value={data.licence}
                    name="licence"
                    error={errors.licence}
                >
                    I acknowledge and accept that my data will be processed in accordance with
                </CheckBoxField>
            </div>
            <button
                disabled={!isValid}
                className={`${cls.submitButton} ${!isValid || loading ? cls.disabledButton : null}`}
            >
                Submit
            </button>
        </form>
    )
}

export default JobApplyForm;
